<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row align="end" justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field placeholder="Procurar" dense solo background-color="#FFFFFF" hide-details
            v-model="search"></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
          <v-btn block color="amber darken-4" dark @click="dialogSave()">
            <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar</v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table :headers="header" :items="objects" dense locale="pt-BR" :search="search" :items-per-page="30">
        <template v-slot:[`item.data`]="{ item }">
          {{ item.data }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Dados">
            <v-btn icon small tile>
              <v-icon @click="dataHolders(item.id)">mdi mdi-database-plus-outline</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remover">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>

    <TheDialog v-model="dialog" :title="title" :icon="pageIcon" width="600px">
      <template>
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col cols="12">
              <b>Nome<sup>*</sup></b>
              <TheInput
                v-model="object.name"
                class="mt-2 mb-n5"
                color="primary"
                :rules="[requiredRule, spaceRule]"
              ></TheInput>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <b>Descrição</b>
              <TheTextarea
                class="mt-2 mb-n6"
                v-model="object.description"
              ></TheTextarea>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions="">
        <v-btn small depressed color="primary" class="mt-1" dark @click="resolveForm()">
          <v-icon small class="mr-2">mdi-plus</v-icon>
          <span v-if="!edit">Adicionar</span>
          <span v-if="edit">Atualizar </span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>
    </TheDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from '@/components/TheTitle'
import TheDialog from '@/components/TheDialog'
import TheToolTip from '@/components/TheToolTip'
import TheTextarea from '@/components/TheTextarea'
import TheInput from '@/components/TheInput'

export default {
  name: 'Dados',
  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
    TheTextarea,
    TheInput,
  },
  data: () => ({
    baseRoute: 'datagroup',
    title: 'Gerência dos Titulares de Dados',
    pageIcon: 'mdi-database-settings',

    object: {},
    datas: [],
    objects: [],

    valid: false,
    dialog: false,
    search: null,
    error: false,
    edit: false,

    header: [
      { text: 'Nome', value: 'name' },
      { text: 'Ações', value: 'action', width: '10%' },
    ],

  }),

  methods: {
    loadData() {
      this.$store.dispatch('setLoadingInfo', true)
      const url = `${baseApiUrl}/${this.baseRoute}`
      axios
        .get(url)
        .then((res) => {
          this.objects = res.data
          console.log("data");
          console.log(this.objects.description);
          this.$store.dispatch('setLoadingInfo', false)
        })
        .catch(showError)
    },
    loadDatas() {
      const url = `${baseApiUrl}/data`
      axios
        .get(url)
        .then((res) => {
          this.datas = res.data
        })
        .catch(showError)
    },

    dialogSave() {
      this.object = {}
      this.edit = false
      this.dialog = true
    },

    async dialogUpdate(item) {
      this.object = item
      this.edit = true
      this.dialog = true
    },

    resolveForm() {
      this.$refs.form.validate()
      if (!this.valid) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text: 'Os campos não foram preenchidos corretamente, você pode verificar?',
          button: 'Ok!',
        })
        return
      }
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },

    save() {
      this.error = false
      const url = `${baseApiUrl}/${this.baseRoute}`
      const objects = this.object
      console.log(objects);
      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    update() {
      this.error = false
      const id = this.object.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object
      
      delete objects.id
      delete objects.data
      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt
      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    remove(id) {
      if (confirm('Remove?')) {
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },
    
    dataHolders(id){
      const route = {
        name: 'Titulares de Dados',
        params: {id: id}
      }
      this.$router.push(route)
    },

  },
  mounted() {
    this.loadData()
    this.loadDatas()
  },
}
</script>

<style>
.full-height {
  height: 80vh;
}
</style>
