<template>
  <v-textarea
    outlined
    class="my-2"
    auto-grow
    v-model="internalValue"
    :label="label"
    :rows="rows"
    :counter="counter"
    :rules="requiredRule"
    @input="$emit('input', internalValue)"
  ></v-textarea>
</template>

<script>
export default {
  name: 'TheTextarea',
  props: {
    value: String,
    label: String,
    requiredRule: Array,
    rows: {
      type: Number,
      default: 3,
    },
    counter: Boolean
  },

  data() {
    return {
      internalValue: this.value
    };
  },

  watch: {
    value(newValue) {
      this.internalValue = newValue;
    }
  },

};
</script>